import { useEffect, useState } from 'react';
import SwiperCore from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';
import Card from 'components/Card';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { featuresSlides } from './miningFeaturesListSwiperContent';
import SectionTitle from 'components/SectionTitle';
import RectangleGridVertical from 'images/svg/backgroundElements/RectangleGridVertical';
import GlowingSquare from 'components/Layout/GlowingSquare';
import { ArrowRightRedirect } from 'images/svg';
import classNames from 'classnames';

SwiperCore.use([Navigation]);

const slidesPerViewConfig = {
  350: 1,
  700: 2,
  1050: 3,
  1754: 4,
};

const getSlidesPerView = width => {
  let result = 1;

  Object.keys(slidesPerViewConfig).forEach(breakpoint => {
    if (width >= breakpoint) {
      result = slidesPerViewConfig[breakpoint];
    }
  });

  return result;
};

let timeout;

const MiningFeaturesListSwiper = () => {
  const { t } = useTranslation();

  const [slidesPerView, setSlidesPerView] = useState<number>(1);
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();

  const contentTitleButtons = classNames(
    'feature-list-swiper__content-title__buttons',
    {
      'feature-list-swiper__content-title__buttons--disabled':
        slidesPerView === Object.values(slidesPerViewConfig).pop(),
    },
  );

  const prevHandler = () => {
    swiperRef?.slidePrev();
  };

  const nextHandler = () => {
    swiperRef?.slideNext();
  };

  useEffect(() => {
    setSlidesPerView(getSlidesPerView(window.innerWidth));
  }, []);

  useEffect(() => {
    const onResize = e => {
      if (timeout) {
        window.cancelAnimationFrame(timeout);
      }

      timeout = window.requestAnimationFrame(() => {
        const currentWidth = e.target?.innerWidth || 0;

        const calculatedSlidesPerView = getSlidesPerView(currentWidth);

        if (calculatedSlidesPerView !== slidesPerView) {
          setSlidesPerView(calculatedSlidesPerView);
        }
      });
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [slidesPerView]);

  return (
    <>
      <div className="feature-list-swiper">
        <RectangleGridVertical />
        <div className="feature-list-swiper__content-wrapper">
          <div className="feature-list-swiper__content-title">
            <SectionTitle
              header="mining.featuresListSwiper.header"
              description="mining.featuresListSwiper.description"
            />
            <div className={contentTitleButtons}>
              <button className="swiper-button-prev" onClick={prevHandler} />
              <button className="swiper-button-next" onClick={nextHandler} />
            </div>
          </div>
          <div className="swiper-content-wrapper">
            <GlowingSquare
              parentClassName="swiper-content-wrapper"
              squareSideLengthRelativeToScreen={50}
              positionHorizontal="left"
              positionVertical="bottom"
            />
            <Swiper
              initialSlide={0}
              slidesPerView={slidesPerView}
              spaceBetween={30}
              centeredSlidesBounds={true}
              onSwiper={swiper => setSwiperRef(swiper)}
              modules={[Pagination]}
              pagination={{
                clickable: true,
              }}
              loop={true}
              wrapperClass="swiper-wrapper"
            >
              {featuresSlides.map((item, index) => (
                <SwiperSlide key={index} className="swiper-slide">
                  <div className="swiper-card__background">
                    <Card
                      header={t(item.header)}
                      description={t(item.description)}
                      icon={item.icon}
                      className="card--small card--highlight swiper-card"
                      iconClassName="card__icon"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="feature-list-swiper__show-all">
            <Link href="/mining/features" legacyBehavior>
              <a className="feature-list-swiper__show-all-link">
                {t('mining.featuresListSwiper.link')}
                <ArrowRightRedirect />
              </a>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default MiningFeaturesListSwiper;
