import { ReactNode } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

interface Props {
  className?: string;
  header: string;
  description: string;
  children?: ReactNode;
}

const FeatureDescription = (props: Props) => {
  const { className, header, description, children } = props;

  const { t } = useTranslation();

  const classes = classNames('feature-description', className);

  return (
    <section className={classes}>
      <h2 className="feature-description__header">{t(header)}</h2>
      <p className="feature-description__description">{t(description)}</p>
      <>{children}</>
    </section>
  );
};

export default FeatureDescription;
