import { HddIcon, SsdIcon, FlashDriveIcon } from 'images/svg';
import { useTranslation } from 'next-i18next';

const MiningHardwareDevices = () => {
  const { t } = useTranslation();

  return (
    <div className="hardware-devices">
      <p className="hardware-devices__description header__description">
        {t('mining.hardwareDevices.headerDescription')}
      </p>
      <div className="hardware-devices__icons-wrapper">
        <div className="hardware-devices__item">
          <HddIcon />
          <span>{t('mining.hardwareDevices.device.hdd')}</span>
        </div>
        <div className="hardware-devices__item">
          <SsdIcon />
          <span>{t('mining.hardwareDevices.device.ssd')}</span>
        </div>
        <div className="hardware-devices__item">
          <FlashDriveIcon />
          <span>{t('mining.hardwareDevices.device.usb')}</span>
        </div>
      </div>
    </div>
  );
};

export default MiningHardwareDevices;
