import { ButtonRegisterOrLogin } from 'components/Layout/Home/ButtonRegisterOrLogin';
import SmLogoLeft from 'images/svg/smLogo/SmLogoLeft';
import SmLogoRight from 'images/svg/smLogo/SmLogoRight';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { getFullUrl } from 'utils/Url';

const StartMining = () => {
  const { t } = useTranslation();

  return (
    <section className="start-mining">
      <div className="start-mining__content">
        <div className="start-mining__background-left">
          <SmLogoLeft />
        </div>
        <h3 className="start-mining__header">{t('startMining.header')}</h3>
        <p className="start-mining__description">
          {t('startMining.description')}
        </p>
        <div className="start-mining__links-wrapper">
          <ButtonRegisterOrLogin
            page="mining"
            className="start-computing__sign-up btn btn-type-white"
          />
          <Link href={getFullUrl('/mining/download')} legacyBehavior>
            <a className="start-mining__download btn btn-type-border">
              {t('page.download')}
            </a>
          </Link>
        </div>
        <div className="start-mining__background-right">
          <SmLogoRight />
        </div>
      </div>
    </section>
  );
};

export default StartMining;
