import { useContext } from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Context } from 'context/GlobalContext';
import classNames from 'classnames';
import { getDashboardUrl } from 'services/EnvProvider';
import { isSimpleMiningUrl } from 'utils/isSimpleMiningUrl';

interface Props {
  page: 'home' | 'mining';
  className?: string;
}

export const ButtonRegisterOrLogin = (props: Props) => {
  const { page, className } = props;

  const dashboardUrl = getDashboardUrl();

  const { t } = useTranslation();

  const { isUserLoggedIn } = useContext(Context);

  const dashboardUrlByPage = `${dashboardUrl}/account/${isSimpleMiningUrl ? 'rigs' : 'find-instances'}`;
  const signUpTitle = page === 'mining' ? 'mining.signUp' : 'home.signUp';

  const buttonClassName = classNames(
    'btn btn-type-blue btn-sign-up',
    className,
  );

  return (
    <>
      {!isUserLoggedIn ? (
        <Link href={`${dashboardUrl}/account/register`} legacyBehavior>
          <a className={buttonClassName}>{t(signUpTitle)}</a>
        </Link>
      ) : (
        <Link href={dashboardUrlByPage} legacyBehavior>
          <a className={buttonClassName}>
            {t('navigation.signMenu.dashboard')}
          </a>
        </Link>
      )}
    </>
  );
};
