import classNames from 'classnames';

const NoCreditCardIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('no-credit-card-logo', className);

  return (
    <svg
      className={classes}
      {...attributes}
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
    >
      <path
        d="M17.0074 10.0205H2.66406C2.2199 10.0205 1.85156 9.65217 1.85156 9.20801C1.85156 8.76384 2.2199 8.39551 2.66406 8.39551H17.0074C17.4516 8.39551 17.8199 8.76384 17.8199 9.20801C17.8199 9.65217 17.4516 10.0205 17.0074 10.0205Z"
        fill="#4A5B6A"
      />
      <path
        d="M8.3975 18.6875H7C6.55583 18.6875 6.1875 18.3192 6.1875 17.875C6.1875 17.4308 6.55583 17.0625 7 17.0625H8.3975C8.84167 17.0625 9.21 17.4308 9.21 17.875C9.21 18.3192 8.8525 18.6875 8.3975 18.6875Z"
        fill="#4A5B6A"
      />
      <path
        d="M16.2057 18.6875H12.4141C11.9699 18.6875 11.6016 18.3192 11.6016 17.875C11.6016 17.4308 11.9699 17.0625 12.4141 17.0625H16.2057C16.6499 17.0625 17.0182 17.4308 17.0182 17.875C17.0182 18.3192 16.6499 18.6875 16.2057 18.6875Z"
        fill="#4A5B6A"
      />
      <path
        d="M19.519 23.0205H9.14062C8.69646 23.0205 8.32812 22.6522 8.32812 22.208C8.32812 21.7639 8.69646 21.3955 9.14062 21.3955H19.519C22.9207 21.3955 23.5165 20.8105 23.5165 17.4522V7.46387C23.5165 7.0197 23.8848 6.65137 24.329 6.65137C24.7732 6.65137 25.1415 7.0197 25.1415 7.46387V17.4414C25.1415 21.7205 23.8307 23.0205 19.519 23.0205Z"
        fill="#4A5B6A"
      />
      <path
        d="M4.51656 22.6302C4.41906 22.6302 4.31073 22.6085 4.21323 22.576C2.2524 21.796 1.85156 19.9652 1.85156 17.4518V8.54685C1.85156 4.27852 3.1624 2.97852 7.47406 2.97852H19.5099C20.7449 2.97852 21.6549 3.07602 22.3699 3.29268C22.8032 3.42268 23.0416 3.86685 22.9224 4.30018C22.7924 4.73352 22.3374 4.97185 21.9149 4.85268C21.3516 4.69018 20.5932 4.61435 19.5099 4.61435H7.47406C4.0724 4.61435 3.47656 5.19935 3.47656 8.55768V17.4518C3.47656 20.041 3.9424 20.7235 4.8199 21.0702C5.2424 21.2327 5.4374 21.7094 5.2749 22.121C5.1449 22.4352 4.84156 22.6302 4.51656 22.6302Z"
        fill="#4A5B6A"
      />
      <path
        d="M2.66135 24.6454C2.45552 24.6454 2.24969 24.5695 2.08719 24.407C1.77302 24.0929 1.77302 23.5729 2.08719 23.2587L23.7538 1.59207C24.068 1.2779 24.588 1.2779 24.9022 1.59207C25.2163 1.90624 25.2163 2.42624 24.9022 2.7404L3.23552 24.407C3.07302 24.5695 2.86719 24.6454 2.66135 24.6454Z"
        fill="#4A5B6A"
      />
    </svg>
  );
};

export default NoCreditCardIcon;
