import { useState, useEffect } from 'react';
import ExportedImage, { ExportedImageProps } from 'next-image-export-optimizer';

const ImageWrapper = (props: ExportedImageProps) => {
  const {
    src,
    alt,
    width,
    height,
    layout,
    objectFit,
    className,
    sizes,
    priority,
    unoptimized = false,
  } = props;

  const [loading, setLoading] = useState<ExportedImageProps['loading']>(
    props.loading || 'lazy',
  );

  useEffect(() => {
    let handleId;

    const onLoad = () => {
      handleId = requestAnimationFrame(() => setLoading('eager'));
    };

    addEventListener('load', onLoad);

    return () => {
      removeEventListener('load', onLoad);
      cancelAnimationFrame(handleId);
    };
  }, [loading]);

  return (
    <ExportedImage
      src={src}
      alt={alt}
      width={width}
      height={height}
      layout={layout}
      className={className}
      objectFit={objectFit}
      sizes={sizes}
      {...(!priority && { loading })}
      priority={priority}
      placeholder="empty"
      unoptimized={unoptimized}
    />
  );
};

export default ImageWrapper;
