import Card from 'components/Card';
import { useTranslation } from 'next-i18next';
import SectionTitle from 'components/SectionTitle';
import ImageWrapper from 'components/ImageWrapper';

const MiningGpusBrands = () => {
  const { t } = useTranslation();

  return (
    <div className="gpus-brands-wrapper">
      <div className="gpus-brands">
        <div className="gpus-brands__content-wrapper">
          <SectionTitle header="mining.gpuBrands.header" />
          <Card
            className="gpus-brands__content-card card--small card--highlight"
            header={t('mining.gpuBrands.card.amd.header')}
            description={
              'Radeon\u00A0RX\u00A0460, 470, 480, 560, 570, 580, 590, VEGA\u00A056/64, Radeon\u00A0VII, RX\u00A05500, RX\u00A05500\u00A0XT, RX\u00A05600, RX5600\u00A0XT, RX\u00A05700, RX\u00A05700 XT, RX\u00A06400, RX\u00A06500\u00A0XT, RX\u00A06600\u00A0XT, RX\u00A06700\u00A0XT, RX\u00A06800, RX\u00A06800\u00A0XT, RX\u00A06900\u00A0XT'
            }
          />
          <Card
            className="gpus-brands__content-card card--small card--highlight"
            header={t('mining.gpuBrands.card.nvidia.header')}
            description={
              '1050, 1050\u00A0Ti, 1060, 1060\u00A0Ti, 1070, 107\u00A0Ti, 1080, 1080\u00A0Ti, P102-100, P104-100, P106-090, P106-100, 1650, 1650\u00A0Ti, 1660, 1660\u00A0Ti, 2060, 2070, 2080, 2080\u00A0Ti, 3050, 3050\u00A0Ti, 3060, 3060\u00A0Ti, 3070, 3070\u00A0Ti, 3080, 3080\u00A0Ti, 3090, CMP\u00A030\u00A0HX, CMP\u00A040\u00A0HX, CMP\u00A050HX, CMP\u00A070HX, CMP\u00A090HX, A2, A16, A100, A2000, A4500, T4G, T400, T1000'
            }
          />
          <h3 className="gpus-brands__content-ending-note header">
            {t('mining.gpuBrands.endingNote')}
          </h3>
        </div>
        <div className="gpus-brands__background">
          <ImageWrapper
            alt="GPU unit"
            src="/img/smAppBackground/sm-gpu-with-background.webp"
            width={1308}
            height={1072}
          />
        </div>
      </div>
    </div>
  );
};

export default MiningGpusBrands;
