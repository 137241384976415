import { ReactNode } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import { ButtonRegisterOrLogin } from 'components/Layout/Home/ButtonRegisterOrLogin';

interface SmallNavOption {
  title: string;
  href: string;
}

interface Props {
  smallNavOptions?: SmallNavOption[];
  className?: string;
  header: string | ReactNode;
  description?: string | ReactNode;
  href?: string;
  children?: ReactNode;
  page: 'home' | 'mining';
}

const PageHero = (props: Props) => {
  const {
    smallNavOptions,
    className,
    header,
    description,
    href,
    children,
    page,
  } = props;

  const classes = classNames('page-hero', className);

  return (
    <section className={classes}>
      {smallNavOptions && (
        <ul className="page-hero__small-nav">
          {smallNavOptions.map((option, index) => (
            <li key={index} className="page-hero__small-nav-item">
              {option.href?.startsWith('#') ? (
                <Link href={option.href}>{option.title}</Link>
              ) : (
                <a href={option.href} target="_blank" rel="noopener noreferrer">
                  {option.title}
                </a>
              )}
            </li>
          ))}
        </ul>
      )}
      <h1 className="page-hero__header header">{header}</h1>
      {description && (
        <p className="page-hero__description">
          <>{description}</>
        </p>
      )}
      {href && <ButtonRegisterOrLogin page={page} />}
      <>{children}</>
    </section>
  );
};

export default PageHero;
