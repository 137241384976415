import { useEffect, useState } from 'react';

interface Price {
  minRange: number;
  maxRange: number;
  rigPrice: number;
  asicPrice: number;
}

interface Props {
  prices;
  price: Price;
  pricesKey: 'rigPrice' | 'asicPrice';
  label: string;
  setPriceData: Function;
}

const PricingEstimatorCounter = (props: Props) => {
  const { prices, price, pricesKey, label, setPriceData } = props;

  const [count, setCount] = useState<number>(1);
  const [currentPricePerUnit, setCurrentPricePerUnit] = useState<number>(
    price[pricesKey],
  );
  const [totalUnitPrice, setTotalUnitPrice] =
    useState<number>(currentPricePerUnit);

  const estimateUnitCost = count => {
    if (isNaN(count)) {
      return;
    }

    setCount(count);

    if (count) {
      prices.forEach(price => {
        if ((count - price.minRange) * (count - price.maxRange) <= 0) {
          setTotalUnitPrice(count * price[pricesKey]);
          setCurrentPricePerUnit(price[pricesKey]);
        }
      });

      return;
    }

    setTotalUnitPrice(0);
  };

  const add = () => {
    estimateUnitCost(count + 1);
  };

  const subtract = () => {
    if (count <= 1) {
      return;
    }

    if (count === 2) {
      setCount(count - 1);
      setTotalUnitPrice(price[pricesKey]);

      return;
    }

    estimateUnitCost(count - 1);
  };

  const onInputChange = e => {
    const currentValue = e.target.value;

    if (currentValue * 1 === 0) {
      estimateUnitCost('');
      setCurrentPricePerUnit(0);

      return;
    }

    if (currentValue.match(/[0-9]/) && currentValue.length <= 9) {
      estimateUnitCost(parseInt(currentValue));

      return;
    }
  };

  const onInputBlur = e => {
    if (!e.target.value) {
      setCount(1);
      setTotalUnitPrice(price[pricesKey]);
      setCurrentPricePerUnit(price[pricesKey]);
    }
  };

  useEffect(() => {
    setPriceData(prev => ({
      ...prev,
      [pricesKey]: {
        pricesKey,
        total: totalUnitPrice,
        perUnit: currentPricePerUnit,
      },
    }));
  }, [currentPricePerUnit, pricesKey, setPriceData, totalUnitPrice]);

  return (
    <div className="pricing-estimator__counter-container">
      <label
        htmlFor="pricing-estimator-input"
        className="pricing-estimator__counter-label"
      >
        {label}
      </label>
      <div className="pricing-estimator__counter">
        <button
          className="pricing-estimator__counter-subtract-btn"
          onClick={subtract}
          aria-label="subtract"
          disabled={[1, ''].includes(count)}
        />
        <input
          id="pricing-estimator-input"
          className="pricing-estimator__counter-input"
          type="text"
          inputMode="decimal"
          pattern="\d*"
          value={count}
          onChange={onInputChange}
          onBlur={onInputBlur}
        />
        <button
          className="pricing-estimator__counter-add-btn"
          onClick={add}
          aria-label="add"
          disabled={!count}
        />
      </div>
    </div>
  );
};

export default PricingEstimatorCounter;
