interface Props {
  children: React.ReactNode;
}

const PageHeroBackground = (props: Props) => {
  const { children } = props;

  return <div className="page-hero-background">{children}</div>;
};

export default PageHeroBackground;
