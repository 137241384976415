export const mostPopular = {
  header: 'mining.minerProgramsList.mostPopular.header',
  items: [
    'phoenixminer',
    'teamredminer',
    't-rex',
    'lolminer',
    'nbminer',
    'gminer',
    'nanominer',
  ],
};

export const lessPopular = {
  header: 'mining.minerProgramsList.lessPopular.header',
  items: [
    'ethminer',
    'srbminer-multi',
    'teamblackminer',
    'miniz',
    'kawpowminer',
    'tt-miner',
    'danila-miner',
    'bminer',
    'finminer',
    'ewbf',
    'wildrig-multi',
  ],
};
