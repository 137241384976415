import { BtcMountainIcon, MinerIcon, BtcHeadphonesIcon } from 'images/svg';
import { useTranslation } from 'next-i18next';
import { lessPopular, mostPopular } from './minerProgramsContent';
import { ButtonRegisterOrLogin } from 'components/Layout/Home/ButtonRegisterOrLogin';

const MinerProgramsList = () => {
  const { t } = useTranslation();

  return (
    <div className="supported-programs">
      <div className="supported-programs__list-wrapper">
        <div className="supported-programs__list-header">
          <MinerIcon />
          <h3>{t(mostPopular.header)}</h3>
        </div>
        <ul className="supported-programs__list">
          {mostPopular.items.map((item, index) => (
            <li key={index} className="supported-programs__list-item">
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className="supported-programs__list-wrapper">
        <div className="supported-programs__list-header">
          <BtcMountainIcon />
          <h3>{t(lessPopular.header)}</h3>
        </div>
        <ul className="supported-programs__list">
          {lessPopular.items.map((item, index) => (
            <li key={index} className="supported-programs__list-item">
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className="supported-programs__custom">
        <div className="supported-programs__custom-wrapper">
          <div className="supported-programs__custom-header">
            <BtcHeadphonesIcon />
            <h3>{t('mining.minerProgramsList.supportedPrograms.header')}</h3>
          </div>
          <p className="supported-programs__custom-description">
            {t('mining.minerProgramsList.supportedPrograms.description')}
          </p>
        </div>
        <ButtonRegisterOrLogin
          className="supported-programs__custom-link"
          page="mining"
        />
      </div>
    </div>
  );
};

export default MinerProgramsList;
