import SectionTitle from 'components/SectionTitle';
import React from 'react';
import PricingTable from './PricingTable';

const Pricing = () => {
  return (
    <div className="pricing" id="pricing">
      <SectionTitle header="pricing.pageHero.header" />
      <PricingTable />
    </div>
  );
};

export default Pricing;
