import DiscordLogoSmall from 'images/svg/socials/DiscordLogoSmall';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { getDashboardUrl } from 'services/EnvProvider';

const MiningSeoDescription = () => {
  const { t } = useTranslation();

  const dashboardUrl = getDashboardUrl();

  return (
    <div className="mining__description">
      <p className="mining__description-text">{t('mining.description')}</p>
      <p className="mining__description-link">
        <span className="mining__description-link-description">
          {t('mining.description.linkDescription')}
        </span>
        <Link href={`${dashboardUrl}/discord`} legacyBehavior>
          <a>
            <DiscordLogoSmall />
            <span>{t('mining.description.link')}</span>
          </a>
        </Link>
      </p>
    </div>
  );
};

export default MiningSeoDescription;
