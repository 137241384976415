import FeatureCard from 'components/FeatureCard';
import { presentationFeatures } from './miningFeaturesPresentationContent';
import SectionTitle from 'components/SectionTitle';
import { ButtonRegisterOrLogin } from 'components/Layout/Home/ButtonRegisterOrLogin';

const MiningFeaturesPresentation = () => {
  return (
    <div className="features-presentation" id="feature">
      <SectionTitle
        header="mining.features.header"
        description="mining.features.description"
      />
      {presentationFeatures.map((feature, index) => (
        <FeatureCard
          key={index}
          invertPosition={index % 2 != 0}
          feature={feature}
        >
          <ButtonRegisterOrLogin className="feature-card__link" page="mining" />
        </FeatureCard>
      ))}
    </div>
  );
};

export default MiningFeaturesPresentation;
