import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import PricingEstimatorCounter from './PricingEstimatorCounter';
import { ButtonRegisterOrLogin } from 'components/Layout/Home/ButtonRegisterOrLogin';

interface Prices {
  minRange: number;
  maxRange: number;
  rigPrice: number;
  asicPrice: number;
}

interface Props {
  prices: Prices[];
}

const PricingEstimator = (props: Props) => {
  const { prices } = props;

  const [priceData, setPriceData] = useState<any>({
    rigPrice: {
      total: prices[0].rigPrice,
      perUnit: prices[0].rigPrice,
    },
    asicPrice: { total: prices[0].asicPrice, perUnit: prices[0].rigPrice },
  });

  const { t } = useTranslation();

  return (
    <div className="pricing-estimator">
      <h4 className="pricing-estimator__header header">
        {t('pricing.estimator.header')}
      </h4>
      <div className="pricing-estimator__counter-wrapper">
        <PricingEstimatorCounter
          prices={prices}
          price={prices[0]}
          pricesKey="rigPrice"
          label={t('pricing.estimator.counter.label.rig')}
          setPriceData={setPriceData}
        />
        {/* <PricingEstimatorCounter
          prices={prices}
          price={prices[0]}
          pricesKey="asicPrice"
          label={t('pricing.estimator.counter.label.asic')}
          setPriceData={setPriceData}
        /> */}

        <div className="pricing-estimator__counter-total-description">
          <span>{t('pricing.estimator.counter.description')}</span>
          <div className="pricing-estimator__counter-total">
            {`${(priceData.rigPrice.total + priceData.asicPrice.total).toFixed(
              2,
            )}$`}
          </div>
          <span>
            {/* {t('pricing.estimator.counter.descriptionPrice', {
              rigPrice: priceData.rigPrice.perUnit.toFixed(1),
              asicPrice: priceData.asicPrice.perUnit.toFixed(1),
            })} */}
            {t('pricing.estimator.counter.descriptionPrice.temporary', {
              rigPrice: priceData.rigPrice.perUnit.toFixed(1),
            })}
          </span>
        </div>
      </div>
      <div className="pricing-table__footnotes">
        <p className="pricing-table__footnotes-text">
          {t('pricing.estimator.footnotes')}
        </p>
        <ButtonRegisterOrLogin page="mining" />
      </div>
    </div>
  );
};

export default PricingEstimator;
// import { useTranslation } from 'next-i18next';
// import { useState } from 'react';
// import PricingEstimatorCounter from './PricingEstimatorCounter';

// interface Prices {
//   minRange: number;
//   maxRange: number;
//   rigPrice: number;
//   asicPrice: number;
// }

// interface Props {
//   prices: Prices[];
// }

// const PricingEstimator = (props: Props) => {
//   const { prices } = props;

//   const [priceData, setPriceData] = useState<any>({
//     rigPrice: {
//       total: prices[0].rigPrice,
//       perUnit: prices[0].rigPrice,
//     },
//     asicPrice: { total: prices[0].asicPrice, perUnit: prices[0].rigPrice },
//   });

//   const { t } = useTranslation();

//   return (
//     <div className="pricing-estimator">
//       <h4 className="pricing-estimator__header header">
//         {t('pricing.estimator.header')}
//       </h4>
//       <div className="pricing-estimator__counter-wrapper">
//         <PricingEstimatorCounter
//           prices={prices}
//           price={prices[0]}
//           pricesKey="rigPrice"
//           label={t('pricing.estimator.counter.label.rig')}
//           setPriceData={setPriceData}
//         />
//         {/* <PricingEstimatorCounter
//           prices={prices}
//           price={prices[0]}
//           pricesKey="asicPrice"
//           label={t('pricing.estimator.counter.label.asic')}
//           setPriceData={setPriceData}
//         /> */}
//         <div className="pricing-estimator__counter-total">{`$${(
//           priceData.rigPrice.total + priceData.asicPrice.total
//         ).toFixed(2)}`}</div>
//         <p className="pricing-estimator__counter-total-description">
//           <span>{t('pricing.estimator.counter.description')}</span>
//           <span>
//             {/* {t('pricing.estimator.counter.descriptionPrice', {
//               rigPrice: priceData.rigPrice.perUnit.toFixed(1),
//               asicPrice: priceData.asicPrice.perUnit.toFixed(1),
//             })} */}
//             {t('pricing.estimator.counter.descriptionPrice.temporary', {
//               rigPrice: priceData.rigPrice.perUnit.toFixed(1),
//             })}
//           </span>
//         </p>
//       </div>
//       <p className="pricing-table__footnotes">
//         {t('pricing.estimator.footnotes')}
//       </p>
//     </div>
//   );
// };

// export default PricingEstimator;
