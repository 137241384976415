import MinerProgramsList from './MinerProgramsList';
import SectionTitle from 'components/SectionTitle';

const MiningMinerPrograms = () => {
  return (
    <div className="supported-miner-programs">
      <SectionTitle
        header="mining.minerPrograms.pageHero.header"
        description="mining.minerPrograms.pageHero.description"
      />
      <MinerProgramsList />
    </div>
  );
};

export default MiningMinerPrograms;
