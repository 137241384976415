import { OsImage } from 'types/OsImageList';
import { ArrowDownloadIcon } from 'images/svg';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

interface Props {
  osImage: OsImage;
}

const DownloadCard = (props: Props) => {
  const { osImage } = props;

  const { t } = useTranslation();

  return (
    <li className="download-card">
      <section className="download-card__description-wrapper">
        <div>
          <span className="download-card__title">{osImage.header}</span>
          <h4 className="download-card__header header">{osImage.title}</h4>
          <p className="download-card__description header__description">
            {osImage.subtitle}
          </p>
        </div>
        <div className="download-card__type">{osImage.type}</div>
      </section>
      <p className="download-card__details">{osImage.details}</p>
      <div className="download-card__download-btn-wrapper">
        <Link href={osImage.link} legacyBehavior>
          <a className="download-card__download-btn btn btn-type-blue">
            <span className="download-card__download-btn-title">
              {t('downloadInstall.card.button')}
            </span>
            <ArrowDownloadIcon />
          </a>
        </Link>
      </div>
    </li>
  );
};

export default DownloadCard;

// // ukrywamy tymczasowo mirros z uwagi na przeniesienie plików do cloudflare
// <div className="download-card__mirror-wrapper">
//   <Link href={osImage.mirrorLink} legacyBehavior>
//     <a className="download-card__mirror-link">
//       <span className="download-card__mirror-link-title">
//         {t('downloadInstall.card.mirror')}
//       </span>
//       <span className="download-card__mirror-link-size">{`- ${osImage.size} mb`}</span>
//     </a>
//   </Link>
// </div>
