import DownloadOsImages from 'components/Layout/Download/DownloadOsImages';
import DownloadCheckTutorial from 'components/Layout/Download/DownloadCheckTutorial';
import DownloadRequirements from 'components/Layout/Download/DownloadRequirements';
import { rigRequirements } from 'components/Layout/Download/DownloadRequirements/requirementsContent';

interface Props {
  data: any;
}

const DownloadGpu = (props: Props) => {
  const { data } = props;

  return (
    <div className="download__content-wrapper">
      <DownloadOsImages
        osImagesList={data}
        headerTitle="downloadInstall.pageHero.header.rig"
      />
      <div className="download__content-requirements">
        <DownloadCheckTutorial
          tutorialPath="/flash-tutorial-gpu"
          headerTitle="downloadInstall.checkTutorial.header.rig"
        />
        <DownloadRequirements requirements={rigRequirements} />
      </div>
    </div>
  );
};

export default DownloadGpu;
