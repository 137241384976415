import PricingList from 'components/Layout/Pricing/PricingList';
import PricingEstimator from 'components/Layout/Pricing/PricingEstimator';
import { prices } from './pricingContent';

const PricingTable = () => {
  return (
    <div className="pricing-table">
      <div className="pricing-table__wrapper">
        <PricingList prices={prices} />
        <PricingEstimator prices={prices} />
      </div>
    </div>
  );
};

export default PricingTable;
