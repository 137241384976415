import { useTranslation } from 'next-i18next';

interface Prices {
  minRange: number;
  maxRange: number;
  rigPrice: number;
  asicPrice: number;
}

interface Props {
  prices: Prices[];
}

const PricingList = (props: Props) => {
  const { prices } = props;

  const { t } = useTranslation();

  return (
    <div className="pricing-list">
      <div className="pricing-list__header header">
        <h4 className="pricing-list__header-title header">
          {t('pricing.list.header.title')}
        </h4>
        <div className="pricing-list__header-description">
          <span className="pricing-list__header-description-title">
            {t('pricing.list.header.description')}
          </span>
          <span className="pricing-list__header-description-footnotes">
            {t('pricing.list.header.footnotes')}
          </span>
        </div>
      </div>
      <ul className="pricing-list__table">
        {prices.map((price, index) => {
          const range =
            price.maxRange < Number.MAX_SAFE_INTEGER
              ? `${price.minRange} - ${price.maxRange}`
              : `${price.minRange}+`;

          return (
            <li key={index} className="pricing-list__table-row">
              <span className="pricing-list__table-row-rigs-range">
                {t('pricing.table.range', { range })}
              </span>
              <div className="pricing-list__table-row-range-price">
                <span>{`${price.rigPrice.toFixed(1)}$`}</span>
                <span>
                  {/* <span className="price-temporary-discount">1.0$</span>
                  {t('pricing.list.asicPriceDescription', {
                    price: price.asicPrice.toFixed(1),
                  })} */}
                </span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PricingList;
