export const prices = [
  {
    minRange: 1,
    maxRange: 49,
    rigPrice: 2,
    asicPrice: 0,
  },
  {
    minRange: 50,
    maxRange: 99,
    rigPrice: 1.8,
    asicPrice: 0,
  },
  {
    minRange: 100,
    maxRange: 249,
    rigPrice: 1.6,
    asicPrice: 0,
  },
  {
    minRange: 250,
    maxRange: 499,
    rigPrice: 1.4,
    asicPrice: 0,
  },
  {
    minRange: 500,
    maxRange: 999,
    rigPrice: 1.2,
    asicPrice: 0,
  },
  {
    minRange: 1000,
    maxRange: 1999,
    rigPrice: 1,
    asicPrice: 0,
  },
  {
    minRange: 2000,
    maxRange: 4999,
    rigPrice: 0.8,
    asicPrice: 0,
  },
  {
    minRange: 5000,
    maxRange: Number.MAX_SAFE_INTEGER,
    rigPrice: 0.5,
    asicPrice: 0,
  },
];
