import { useTranslation } from 'next-i18next';
import SectionTitle from 'components/SectionTitle';
import FlashDriveContent from './FlashDriveContent';
import { ButtonRegisterOrLogin } from 'components/Layout/Home/ButtonRegisterOrLogin';
import ImageWrapper from 'components/ImageWrapper';

const MiningFlashDrive = () => {
  const { t } = useTranslation();

  const DesktopFlashDriveContent = () => (
    <div className="flash-drive-wrapper--desktop">
      <SectionTitle header="mining.deployment.header" />
      <div className="flash-drive">
        <div className="flash-drive__background">
          <ImageWrapper
            src="/img/smAppBackground/sm-flash-with-background.webp"
            alt="Simple Mining app"
            layout="fill"
            objectFit="cover"
            objectPosition="center"
          />
        </div>
        <div className="flash-drive__content">
          <div className="flash-drive__content-text-wrapper">
            <FlashDriveContent
              header={{
                firstSegment: t(
                  'mining.flashDrive.content.top.header.firstSegment',
                ),
                secondSegment: t(
                  'mining.flashDrive.content.top.header.secondSegment',
                ),
              }}
              description={t('mining.flashDrive.content.top.description')}
              placing="top"
            />
            <FlashDriveContent
              header={{
                firstSegment: t(
                  'mining.flashDrive.content.bottom.header.firstSegment',
                ),
                secondSegment: t(
                  'mining.flashDrive.content.bottom.header.secondSegment',
                ),
              }}
              description={t('mining.flashDrive.content.bottom.description')}
              placing="bottom"
            />
          </div>
        </div>
      </div>
      <div className="flash-drive__content-link">
        <p className="">{t('mining.deployment.description')}</p>
        <ButtonRegisterOrLogin page="mining" />
      </div>
    </div>
  );

  const MobileFlashDriveContent = () => (
    <div className="flash-drive-wrapper--mobile">
      <SectionTitle className="title" header={t('mining.deployment.header')} />
      <div className="flash-drive__background">
        <ImageWrapper
          src="/img/smAppBackground/sm-flash-with-background.webp"
          alt="Simple Mining app"
          layout="fill"
          objectFit="cover"
          objectPosition="center"
        />
      </div>
      <div className="flash-drive__content-wrapper">
        <div className="flash-drive__content-text-wrapper">
          <FlashDriveContent
            header={{
              firstSegment: t(
                'mining.flashDrive.content.top.header.firstSegment',
              ),
              secondSegment: t(
                'mining.flashDrive.content.top.header.secondSegment',
              ),
            }}
            description={t('mining.flashDrive.content.top.description')}
            placing="top"
          />
        </div>

        <div>
          <div className="flash-drive__content-text-wrapper">
            <FlashDriveContent
              header={{
                firstSegment: t(
                  'mining.flashDrive.content.bottom.header.firstSegment',
                ),
                secondSegment: t(
                  'mining.flashDrive.content.bottom.header.secondSegment',
                ),
              }}
              description={t('mining.flashDrive.content.bottom.description')}
              placing="bottom"
            />
          </div>
          <div className="flash-drive__content-link">
            <p className="">{t('mining.deployment.description')}</p>
            <ButtonRegisterOrLogin page="mining" />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <MobileFlashDriveContent />
      <DesktopFlashDriveContent />
    </>
  );
};

export default MiningFlashDrive;
