import dynamic from 'next/dynamic';

const getDynamicComponent = iconPath =>
  dynamic(() => import(`images/svg/${iconPath}.tsx`));

interface Props {
  icon: string;
  title: string;
}

const DownloadRequirementsItem = (props: Props) => {
  const { icon, title } = props;

  const DynamicComponent = getDynamicComponent(icon);

  return (
    <li className="download-requirements__list-item">
      <span className="download-requirements__list-item-icon">
        <DynamicComponent />
      </span>
      <span className="download-requirements__list-item-title">{title}</span>
    </li>
  );
};

export default DownloadRequirementsItem;
