const mediaSourceSize = { width: 2384, height: 1826 };

export const presentationFeatures = [
  {
    header: 'mining.featuresPresentation.features.first.header',
    description: 'mining.featuresPresentation.features.first.description',
    mediaSource: {
      path: '/img/smAppScreens/rig-monitor.webp',
      description: '',
      size: mediaSourceSize,
    },
  },
  {
    header: 'mining.featuresPresentation.features.second.header',
    description: 'mining.featuresPresentation.features.second.description',
    mediaSource: {
      path: '/img/smAppScreens/rig-details.webp',
      description: '',
      size: mediaSourceSize,
    },
  },
  {
    header: 'mining.featuresPresentation.features.third.header',
    description: 'mining.featuresPresentation.features.third.description',
    mediaSource: {
      path: '/img/smAppScreens/rig-manage.webp',
      description: '',
      size: mediaSourceSize,
    },
  },
  {
    header: 'mining.featuresPresentation.features.fourth.header',
    description: 'mining.featuresPresentation.features.fourth.description',
    mediaSource: {
      path: '/img/smAppScreens/rig-overclock.webp',
      description: '',
      size: mediaSourceSize,
    },
  },
  {
    header: 'mining.featuresPresentation.features.fifth.header',
    description: 'mining.featuresPresentation.features.fifth.description',
    mediaSource: {
      path: '/img/smAppScreens/rig-statistics.webp',
      description: '',
      size: mediaSourceSize,
    },
  },
];
