export const featuresSlides = [
  {
    header: 'mining.featuresListSwiper.featuresSlides.boost.header',
    description: 'mining.featuresListSwiper.featuresSlides.boost.description',
    icon: 'features/BoostIcon',
  },
  {
    header: 'mining.featuresListSwiper.featuresSlides.dashboard.header',
    description:
      'mining.featuresListSwiper.featuresSlides.dashboard.description',
    icon: 'features/CloudIcon',
  },
  {
    header: 'mining.featuresListSwiper.featuresSlides.updates.header',
    description: 'mining.featuresListSwiper.featuresSlides.updates.description',
    icon: 'features/UpdateIcon',
  },
  {
    header: 'mining.featuresListSwiper.featuresSlides.support.header',
    description: 'mining.featuresListSwiper.featuresSlides.support.description',
    icon: 'features/SupportIcon',
  },
];
