import { ReactNode } from 'react';
import classNames from 'classnames';
import FeatureDescription from 'components/FeatureCard/FeatureCardDescription';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import GlowingSquare from 'components/Layout/GlowingSquare';
import ImageWrapper from 'components/ImageWrapper';

interface MediaSource {
  path: string;
  description: string;
}

interface Feature {
  header: string;
  description: string;
  mediaSource: MediaSource;
  showMoreHref?: string;
  isGlowingSquareVisible?: boolean;
  glowingSquarePosition?: any;
}

interface Props {
  feature: Feature;
  className?: string;
  invertPosition?: boolean;
  children?: ReactNode;
}

const FeatureCard = (props: Props) => {
  const {
    feature: {
      header,
      description,
      mediaSource: { path, description: alt },
      showMoreHref,
      isGlowingSquareVisible,
      glowingSquarePosition,
    },
    className,
    invertPosition,
    children,
  } = props;

  const { t } = useTranslation();

  const classes = classNames('feature-card', className, {
    'feature-card--invert': invertPosition,
  });

  const descriptionClasses = classNames('feature-card__media-description', {
    'feature-card__media-description-image-btn--visible': showMoreHref,
  });

  return (
    <div className={classes}>
      <div className="feature-card__media-source">
        <div className="feature-card__media-source-image-wrapper">
          <ImageWrapper
            src={path}
            alt={alt}
            width={1560}
            height={1266}
            className="feature-card__media-source-image"
            layout="responsive"
          />
        </div>
        {showMoreHref && (
          <Link href={showMoreHref} legacyBehavior>
            <a className="example__image-btn btn btn-type-blue btn-sign-up">
              {t('featured.card.show.more.link')}
            </a>
          </Link>
        )}
        {isGlowingSquareVisible && (
          <GlowingSquare
            parentClassName="feature-card__media-source"
            positionHorizontal={glowingSquarePosition?.positionHorizontal}
            positionVertical={glowingSquarePosition?.positionVertical}
            squareSideLengthRelativeToScreen={50}
          />
        )}
      </div>

      <div className={descriptionClasses}>
        <FeatureDescription
          className="feature-card__description-wrapper"
          header={header}
          description={description}
        >
          {children}
        </FeatureDescription>
      </div>
    </div>
  );
};

export default FeatureCard;
