import { ManualIcon } from 'images/svg';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { getFullUrl } from 'utils/Url';

interface Props {
  headerTitle: string;
  tutorialPath: string;
}

const DownloadCheckTutorial = (props: Props) => {
  const { headerTitle, tutorialPath } = props;

  const { t } = useTranslation();

  return (
    <div className="download-check-tutorial">
      <ManualIcon />
      <h3 className="download-check-tutorial__header header">
        {t(`${headerTitle}`)}
      </h3>
      <p className="download-check-tutorial__header-description header__description">
        {t('downloadInstall.checkTutorial.description')}
      </p>
      <Link href={getFullUrl(`/mining${tutorialPath}`)} legacyBehavior>
        <a className="download-check-tutorial__btn btn btn-type-white">
          {t('downloadInstall.checkTutorial.link')}
        </a>
      </Link>
    </div>
  );
};

export default DownloadCheckTutorial;
