import DownloadGpu from 'components/Layout/Download/DownloadGpu';
import SectionTitle from 'components/SectionTitle';
// import DownloadAsic from 'components/Layout/Download//DownloadAsic';
import Tabs from 'components/Tabs';

interface Props {
  data: any;
}

// const betaBadge = <span className="navigation-beta-badge">Beta</span>;

const tabs: Tab[] = [
  {
    title: 'Gpu',
    type: 'rig',
    iconPath: 'downloadTabs/GpuTabIcon',
    component: DownloadGpu,
  },
  // {
  //   title: 'ASIC Hub',
  //   type: 'asic',
  //   iconPath: 'downloadTabs/AsicTabIcon',
  //   component: DownloadAsic,
  //   badge: betaBadge,
  // },
];

const DownloadTabs = (props: Props) => {
  const { data } = props;

  return (
    <>
      <SectionTitle
        header="downloadInstall.pageHero.header.rig"
        description="downloadInstall.pageHero.description"
      />
      <Tabs tabs={tabs} data={data} />
    </>
  );
};

export default DownloadTabs;
