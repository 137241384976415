import classNames from 'classnames';
import TextArrow from './TextArrow';

interface Props {
  header: {
    firstSegment: string;
    secondSegment: string;
  };
  description: string;
  placing: 'top' | 'bottom';
}

const FlashDriveContent = (props: Props) => {
  const {
    header: { firstSegment, secondSegment },
    description,
    placing,
  } = props;

  const className = classNames({
    'flash-drive__content-text-top': placing === 'top',
    'flash-drive__content-text-bottom': placing === 'bottom',
  });

  return (
    <div className={className}>
      {/* <TextArrow screenSize="mobile" /> */}
      <section className="flash-drive__content-text">
        <h3 className="flash-drive__content-text-header">
          <span>{firstSegment}</span>
          {secondSegment}
        </h3>
        <TextArrow screenSize="desktop" />
        <p className="flash-drive__content-text-description">{description}</p>
      </section>
    </div>
  );
};

export default FlashDriveContent;
