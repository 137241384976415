import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

interface Props {
  header: string;
  description?: string;
  className?: string;
  id?: string;
  href?: string;
  linkTitle?: string;
  children?: React.ReactNode;
}

const SectionTitle = (props: Props) => {
  const { header, description, className, id, href, linkTitle, children } =
    props;

  const { t } = useTranslation();

  const sectionClassName = classNames('section-title', className);

  return (
    <section className={sectionClassName} id={id}>
      <h2 className="section-title__header">{t(header)}</h2>
      {description && (
        <p className="section-title__description">{t(description)}</p>
      )}
      {href && linkTitle && (
        <Link href={href} legacyBehavior>
          <a className="btn btn-type-blue btn-sign-up">{t(linkTitle)}</a>
        </Link>
      )}
      {children && <>{children}</>}
    </section>
  );
};

export default SectionTitle;
