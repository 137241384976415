import { useState } from 'react';
// import classNames from 'classnames';
// import TabContent from './TabContent';

interface Props {
  data: any;
  tabs: Tab[];
}

const Tabs = (props: Props) => {
  const { data, tabs } = props;

  // const [openTabIndex, setOpenTabIndex] = useState(0);
  const [openTabIndex] = useState(0);

  // const getItemClasses = index =>
  //   classNames('tabs__navigation-item', {
  //     active: index === openTabIndex,
  //   });

  // const onTabClick = index => {
  //   setOpenTabIndex(index);
  // };

  const tabFilterData = data.filter(
    dataItem => dataItem.osSeriesType === tabs[openTabIndex].type,
  );

  const ActiveComponent = tabs[openTabIndex].component;

  return (
    <div className="tabs" id="download">
      {/* <div className="tabs__navigation-wrapper">
        <div className="tabs__navigation-gap--left"></div>
        <ul className="tabs__navigation-list">
          {tabs.map((tab, index) => (
            <li key={tab.title}>
              <div
                className={getItemClasses(index)}
                onClick={() => onTabClick(index)}
              >
                <TabContent
                  iconPath={tab.iconPath}
                  title={tab.title}
                  badge={tab.badge}
                />
              </div>
              {index < tabs.length - 1 && (
                <div className="tabs__navigation-gap--center"></div>
              )}
            </li>
          ))}
        </ul>
        <div className="tabs__navigation-gap--right"></div>
      </div> */}
      <ActiveComponent data={tabFilterData} />
    </div>
  );
};

export default Tabs;
