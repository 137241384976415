import classNames from 'classnames';

const TextArrow = ({ screenSize }: { screenSize: 'mobile' | 'desktop' }) => {
  const className = classNames('flash-drive__content-text-line', {
    'flash-drive__content-text-line--mobile': screenSize === 'mobile',
    'flash-drive__content-text-line--desktop': screenSize === 'desktop',
  });

  return (
    <div className={className}>
      <hr className="flash-drive__content-text-line-arrow" />
      <div className="flash-drive__content-text-line-ball" />
    </div>
  );
};

export default TextArrow;
