import { Trans, useTranslation } from 'next-i18next';
import PageHero from 'components/Layout/PageHero';
import PageHeroBackground from 'components/Layout/PageHeroBackground';
import GlowingSquare from 'components/Layout/GlowingSquare';
import NoCreditCardIcon from 'images/svg/miningPageHero/NoCreditCardIcon';
import NoFileIcon from 'images/svg/miningPageHero/NoFileIcon';
import ScheduleIcon from 'images/svg/miningPageHero/ScheduleIcon';
import { getPublicApiUrl, getDashboardUrl } from 'services/EnvProvider';

const MiningPageHero = () => {
  const dashboardUrl = getDashboardUrl();
  const apiUrl = getPublicApiUrl();

  const { t } = useTranslation();

  const miningHeroSmallNavigation = [
    { title: t('mining.pageHero.smallNavigation.features'), href: '#feature' },
    { title: t('mining.pageHero.smallNavigation.pricing'), href: '#pricing' },
    { title: t('mining.pageHero.smallNavigation.download'), href: '#download' },
    {
      title: t('footnotes.apiLink'),
      href: `${apiUrl}/docs`,
    },
  ];

  const miningHeroFootnotes = [
    {
      text: t('mining.pageHero.footnotes.noCreditCard'),
      icon: NoCreditCardIcon,
    },
    { text: t('mining.pageHero.footnotes.freeTrial'), icon: ScheduleIcon },
    { text: t('mining.pageHero.footnotes.noCommitment'), icon: NoFileIcon },
  ];

  return (
    <PageHeroBackground>
      <GlowingSquare
        squareSideLengthRelativeToScreen={100}
        positionHorizontal="center"
        positionVertical="bottom"
        parentClassName="page-hero"
      />
      <PageHero
        smallNavOptions={miningHeroSmallNavigation}
        header={
          <Trans
            i18nKey="mining.pageHero.description.header"
            components={{
              0: <p />,
              1: <p />,
              2: <span className="blue-text-marker">for cryptocurrency</span>,
              3: <p className="blue-text-marker" />,
            }}
          />
        }
        description={
          <>
            {t('mining.pageHero.description.firstSegment')}
            <span className="mining__hero-description">
              {t('mining.pageHero.description.secondSegment')}
            </span>
          </>
        }
        href={`${dashboardUrl}/account/register`}
        page="mining"
      >
        <ul className="mining__hero-footnotes">
          {miningHeroFootnotes.map((footnote, index) => {
            const IconComponent = footnote.icon;

            return (
              <li className="mining__hero-footnotes-item" key={index}>
                <span>
                  <IconComponent />
                </span>
                <span>{footnote.text}</span>
              </li>
            );
          })}
        </ul>
      </PageHero>
    </PageHeroBackground>
  );
};

export default MiningPageHero;
