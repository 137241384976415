import { useTranslation } from 'next-i18next';
import DownloadRequirementsItem from './DownloadRequirementsItem';

interface Requirements {
  title: string;
  icon: string;
}

interface Props {
  requirements: Requirements[];
}

const DownloadRequirements = (props: Props) => {
  const { requirements } = props;

  const { t } = useTranslation();

  return (
    <div className="download-requirements">
      <h3 className="download-requirements__header header">
        {t('downloadInstall.requirements.header')}
      </h3>
      <ul className="download-requirements__list">
        {requirements?.map(requirement => (
          <DownloadRequirementsItem
            key={requirement.title}
            title={t(requirement.title)}
            icon={requirement.icon}
          />
        ))}
      </ul>
    </div>
  );
};

export default DownloadRequirements;
