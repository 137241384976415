import classNames from 'classnames';

const NoFileIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('no-file-logo', className);

  return (
    <svg
      className={classes}
      {...attributes}
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
    >
      <path
        d="M22.1186 8.13138L15.9936 2.00637C15.8296 1.84227 15.607 1.75005 15.375 1.75H5.75C5.05381 1.75 4.38613 2.02656 3.89384 2.51884C3.40156 3.01113 3.125 3.67881 3.125 4.375V23.625C3.125 24.3212 3.40156 24.9889 3.89384 25.4812C4.38613 25.9734 5.05381 26.25 5.75 26.25H14.5C14.7321 26.25 14.9546 26.1578 15.1187 25.9937C15.2828 25.8296 15.375 25.6071 15.375 25.375C15.375 25.1429 15.2828 24.9204 15.1187 24.7563C14.9546 24.5922 14.7321 24.5 14.5 24.5H5.75C5.51794 24.5 5.29538 24.4078 5.13128 24.2437C4.96719 24.0796 4.875 23.8571 4.875 23.625V4.375C4.875 4.14294 4.96719 3.92038 5.13128 3.75628C5.29538 3.59219 5.51794 3.5 5.75 3.5H14.5V7C14.5 7.69619 14.7766 8.36387 15.2688 8.85616C15.7611 9.34844 16.4288 9.625 17.125 9.625H20.625V12.25C20.625 12.4821 20.7172 12.7046 20.8813 12.8687C21.0454 13.0328 21.2679 13.125 21.5 13.125C21.7321 13.125 21.9546 13.0328 22.1187 12.8687C22.2828 12.7046 22.375 12.4821 22.375 12.25V8.75C22.375 8.51795 22.2827 8.29543 22.1186 8.13138ZM16.25 7V4.73725L19.3878 7.875H17.125C16.8929 7.875 16.6704 7.78281 16.5063 7.61872C16.3422 7.45462 16.25 7.23206 16.25 7Z"
        fill="#4A5B6A"
      />
      <path
        d="M19.75 14C18.5386 14 17.3544 14.3592 16.3471 15.0322C15.3399 15.7053 14.5548 16.6619 14.0912 17.7811C13.6277 18.9003 13.5064 20.1318 13.7427 21.3199C13.979 22.5081 14.5624 23.5994 15.419 24.456C16.2756 25.3126 17.3669 25.896 18.5551 26.1323C19.7432 26.3686 20.9747 26.2473 22.0939 25.7838C23.2131 25.3202 24.1697 24.5351 24.8428 23.5279C25.5158 22.5206 25.875 21.3364 25.875 20.125C25.8731 18.5011 25.2272 16.9443 24.079 15.796C22.9307 14.6478 21.3739 14.0019 19.75 14ZM16.656 17.031C17.37 16.3194 18.3075 15.8756 19.3104 15.7744C20.3134 15.6732 21.3206 15.9208 22.1624 16.4754L16.1004 22.5374C15.5458 21.6956 15.2982 20.6884 15.3994 19.6854C15.5006 18.6825 15.9444 17.745 16.656 17.031ZM22.844 23.219C22.13 23.9306 21.1925 24.3744 20.1896 24.4756C19.1866 24.5768 18.1794 24.3292 17.3376 23.7746L23.3996 17.7126C23.9542 18.5544 24.2018 19.5616 24.1006 20.5646C23.9994 21.5675 23.5556 22.505 22.844 23.219Z"
        fill="#4A5B6A"
      />
    </svg>
  );
};

export default NoFileIcon;
