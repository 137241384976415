export const rigRequirements = [
  {
    icon: 'hardwareDevices/PcIcon',
    title: 'downloadInstall.requirements.rig.pc',
  },
  {
    icon: 'hardwareDevices/HddDriveIcon',
    title: 'downloadInstall.requirements.rig.hdd',
  },
  {
    icon: 'hardwareDevices/InternetPortIcon',
    title: 'downloadInstall.requirements.rig.internet',
  },
  {
    icon: 'hardwareDevices/WiFiIcon',
    title: 'downloadInstall.requirements.rig.wifi',
  },
];

export const asicRequirements = [
  {
    icon: 'hardwareDevices/PcIcon',
    title: 'downloadInstall.requirements.asic.pc',
  },
  {
    icon: 'hardwareDevices/HddDriveIcon',
    title: 'downloadInstall.requirements.asic.hdd',
  },
  {
    icon: 'hardwareDevices/InternetPortIcon',
    title: 'downloadInstall.requirements.asic.internet',
  },
];
